import React from "react";

import MeasureLogo from "../../images/Measure Logo.png";
import Channel from "../../images/Channel.png";
import Dashboard from "../../images/Dashboard.png";
import Invite from "../../images/Invite.png";

import PortfolioPage from "../../components/portfolioPage";
import Highlight from "../../components/highlight";

const links = [
	{
		endpoint: "https://measure.jeremybinder.dev/",
		title: "Demo",
	},
	{
		endpoint: "https://github.com/FellowshipOfThePing/Measure-Demo",
		title: "Github",
	},
];

const highlights = [
	{
		image: Channel,
		text: "Organize your workspace with channels and dashboards",
	},
	{
		image: Dashboard,
		text: "Create and edit data visualizations in the dashboard view",
	},
	{
		image: Invite,
		text: "Invite friends or co-workers to collaborate in your workspace",
	},
];

const Measure = () => (
	<PortfolioPage
		image={MeasureLogo}
		name="Measure"
		description="A collaborative no-code dashboard platform for business intelligence, now defunct."
		links={links}
	>
		{highlights.map((item, index) => (
			<Highlight
				text={item.text}
				image={item.image}
				style={
					index === 0
						? {
								borderTop: "1px solid black",
								justifyContent: "space-between",
						  }
						: { justifyContent: "space-between" }
				}
				heightRatio={1.2}
				textLeft={index % 2 === 0}
			/>
		))}
	</PortfolioPage>
);

export default Measure;
