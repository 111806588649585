import React from "react";
import styled from "styled-components";

const itemHeight = 350;

const StyledContainer = styled.div`
	width: 100%;
	height: ${itemHeight}px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	overflow: visible;
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: ${itemHeight}px;
`;

const TextContainer = styled.div`
	height: 80%;
	max-width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
`;

const Highlight = ({
	image,
	text,
	textLeft = true,
	heightRatio = 1,
	...props
}) => (
	<StyledContainer {...props}>
		{textLeft && (
			<TextContainer>
				<h4
					style={{
						margin: "0",
						textDecoration: "none",
						textAlign: "center",
						width: "80%",
					}}
				>
					{text}
				</h4>
			</TextContainer>
		)}

		<ImageContainer>
			<img
				alt=""
				src={image}
				height={itemHeight / heightRatio}
				style={{
					borderRadius: "8px",
					boxShadow: "0px 10px 15px rgba(96, 100, 112, 0.2)",
				}}
			/>
		</ImageContainer>

		{!textLeft && (
			<TextContainer>
				<h4
					style={{
						margin: "0",
						textDecoration: "none",
						textAlign: "center",
					}}
				>
					{text}
				</h4>
			</TextContainer>
		)}
	</StyledContainer>
);

export default Highlight;
